import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (

  <div>
    {/* <h1 style={{ color: 'orange', fontWeight: 'bold' }}>Ikanci</h1> */}
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
      {/* <div className="logo">
      <span className="icon fa-diamond"></span>
    </div> */}
      <div className="content">
        <div className="inner">
          {/* <h1>Ikanci</h1> */}
          <h1 style={{ color: 'white', fontWeight: 'bold' }}>Ikanci</h1>
          <p>
            Nos dedicacmos a brindar soluciones a empresas, para poder potenciar sus procesos y generar valor a sus productos o servicios.
        </p>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('intro')
              }}
            >
              <div style={{ color: 'white' }}>&nbsp; &nbsp; Inicio &nbsp;&nbsp;</div>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('work')
              }}
            >
              <div style={{ color: 'white' }}>Servicios</div>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('about')
              }}
            >
              <div style={{ color: 'white' }}>Nosotros</div>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              }}
            >
              <div style={{ color: 'white' }}>Contactos</div>
            </button>
          </li>
        </ul>
      </nav>
    </header>
  </div>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Header
